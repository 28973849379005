<template>
    <div class="confirm-processing-personal-data-checkbox">
        <div class="checkmark-wrapper" @click="toggle">
            <Checkmark :active="active"/>
        </div>
        <div class="confirm-text">
            <slot/>
        </div>
    </div>
</template>

<script>
import Checkmark from "@/components/_elements/form/checkmark";
export default {
    name: "ConfirmProcessingPersonalDataCheckbox",
    components: {Checkmark},
    model: {
        prop: 'active',
        event: 'change'
    },
    props: {
        active: {}
    },
    methods: {
        toggle() {
            this.$emit('change', !this.active);
        },
    },
}
</script>

<style lang="scss">
.confirm-processing-personal-data-checkbox {
    display: flex;
    align-items: flex-start;

    margin-bottom: 24px;

    .checkmark-wrapper {
        padding-right: 20px;
        cursor: pointer;

        @include mobile {
            padding-right: 14px;
        }

        .checkmark-holder {
            @include mobileUp {
                width: 26px;
                height: 26px;
            }
        }
    }

    .confirm-text {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: $blackBlue;

        @include mobile {
            font-size: 12px;
            line-height: 18px;
        }

        a {
            color: $primaryDark;

            &:hover {
                text-decoration: none;
                color: $primary;
            }
        }
    }
}
</style>

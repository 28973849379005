export const outVModel = {
    props: {
        value: {},
        allowNegativeNumber: Boolean
    },
    computed: {
        VModelValue: {
            set(value) {
                if (value !== this.value) {
                    if (this.isNumberTypeInput && !this.allowNegativeNumber && value < 0) {
                        this.$forceUpdate();
                    } else {
                        this.$emit('input', value)
                    }
                }
            },
            get() {
                return this.value;
            }
        },
        isNumberTypeInput() {
            return (this.type || this.$attrs.type) === 'number';
        },
    }
}

<template>
    <span
        class="error-text-form"
        :class="{center, large}"
        v-if="errors"
    >
        <span v-for="(e, i) in errors" :key="i">{{ e }}</span>
    </span>
</template>

<script>
export default {
    name: "errors",
    props: {
        errors: {},
        center: {
            type: Boolean,
            default: false,
        },
        large: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="scss">
    .error-text-form {
        display: block;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.02em;
        color: $error;

        margin-top: 4px;

        &.center {
            text-align: center;
        }

        &.large {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.01em;
        }

        span {
            display: block;
        }
    }
</style>

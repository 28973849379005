<template>
    <router-link :to="{name: linkName}" class="auth-change-link" replace>
        {{ label }}
    </router-link>
</template>

<script>
export default {
    name: "AuthChangeLink",
    props: {
        label: String,
        linkName: String,
    }
}
</script>

<style lang="scss">
    .auth-change-link {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.01em;
        text-decoration: none;
        color: #307EFD;

        display: flex;
        justify-content: center;
        margin-bottom: 40px;
    }
</style>

<template>
    <div class="checkmark-holder" :class="{active}">
        <img v-lazy-load class="checkmark" src="@/assets/img/icons/checkbox-block-checkmark.svg" alt="">
    </div>
</template>

<script>
    export default {
        name: "checkmark",
        model: {
            prop: 'active',
            event: 'change'
        },
        props: {
            active: {}
        }
    }
</script>

<style lang="scss">
    .checkmark-holder {
        @include flexCenter(flex);

        height: 24px;
        width: 24px;
        flex-shrink: 0;

        border-radius: 6px;
        border: 2px solid #DADEE7;

        cursor: pointer;

        &, .checkmark {
            @include transition(all, 250ms)
        }

        .checkmark {
            opacity: 0;
            transform: translateY(50%);
        }

        &.active {
            background: #2489FF;
            border-color: #2489FF;

            .checkmark {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }
</style>

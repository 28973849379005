<template>
    <ConfirmProcessingPersonalDataCheckbox v-model="confirmModel">
        Даю согласие на <a target="_blank" href="/docs/Audi_consent_to_communication.pdf">обработку моих персональных
        данных</a> с целью дальнейшей коммуникации
    </ConfirmProcessingPersonalDataCheckbox>
</template>

<script>
import ConfirmProcessingPersonalDataCheckbox from "@/components/brands/templates/ConfirmProcessingPersonalDataCheckbox";

export default {
    name: "AudiConfirmProcessingPersonalDataCheckbox",
    components: {ConfirmProcessingPersonalDataCheckbox},
    model: ConfirmProcessingPersonalDataCheckbox.model,
    props: {
        active: {},
    },
    computed: {
        confirmModel: {
            set(confirmed) {
                this.$emit('change', confirmed);
            },
            get() {
                return this.active;
            },
        },
    },
}
</script>

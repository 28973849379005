<template>
    <label
        class="round-input"
        :class="{
        disabled,
        error,
        confirmed,
        large,
        large80,
        large65,
        small,
        uppercase,
        'clear-btn': clearBtn,
        'p-l': iconAfter,
        'hide-appearance': hideAppearance,
        'placeholder-accent': placeholderAccent,
          }"
    >
        <label class="input-wrapper">
            <component class="icon-after" :is="iconAfter" v-if="iconAfter"/>
            <input
                ref="input"
                :type="typeName"
                :class="{error}"
                v-bind="$attrs"
                :placeholder="placeholderToShow"
                :disabled="disabled"
                v-on="listeners"
                v-model="VModelValue"
                @focus="onFocus"
                @blur="onBlur"
            >
            <span v-if="staticPostfix" class="static-input-postfix">
                {{ staticPostfix }}
            </span>
            <img v-lazy-load src="@/assets/img/auth/input-clean.svg" class="btn" @click="$emit('input', '')" v-if="clearBtn">
            <template v-if="isPassword">
                <img v-lazy-load src="@/assets/img/auth/input-hide-password.svg" class="btn" @click="toggleShowPassword"
                     v-if="showPassword">
                <img v-lazy-load src="@/assets/img/auth/input-show-password.svg" class="btn" @click="toggleShowPassword" v-else>
            </template>
        </label>
        <errors :errors="errors"/>
    </label>
</template>

<script>
import Errors from "@/components/_elements/errors";
import {outVModel} from "@/mixins/outVModel";

export default {
    name: "roundInput",
    components: {Errors},
    mixins: [outVModel],
    props: {
        errors: Array,
        disabled: Boolean,
        placeholder: String,
        placeholderAccent: String,
        type: String,
        confirmed: Boolean,
        large: Boolean,
        large80: Boolean,
        large65: Boolean,
        clearBtn: Boolean,
        small: Boolean,
        uppercase: Boolean,
        staticPostfix: String,
        hideAppearance: Boolean,
        iconAfter: {},
    },
    inheritAttrs: false,
    data: () => ({
        showPassword: false,
        focused: false,
    }),
    computed: {
        listeners() {
            const {input, focus, ...listeners} = this.$listeners;
            return listeners;
        },
        error() {
            return !!this.errors?.length;
        },
        placeholderToShow() {
            if (this.placeholderAccent && !this.focused) {
                return this.placeholderAccent;
            }
            return this.placeholder;
        },
        isPassword() {
            return this.type === 'password';
        },
        typeName() {
            if (this.isPassword) {
                return this.showPassword ? 'text' : 'password';
            }
            return this.type;
        },
    },
    methods: {
        toggleShowPassword() {
            this.showPassword = !this.showPassword;
        },
        onFocus(event) {
            this.focused = true;
            this.$emit('focus', event);
        },
        onBlur(event) {
            this.focused = false;
            this.$emit('blur', event);
        },
    }
}
</script>

<style lang="scss">
.round-input {
    position: relative;
    width: 100%;
    margin-bottom: 0;

    .input-wrapper {
        position: relative;
        width: inherit;

        & + .error-text-form {
            margin-top: 4px;
        }
    }

    &.confirmed input {
        padding-right: 50px;

        background-image: url("~@/assets/img/icons/input-confirm.svg");
        background-repeat: no-repeat;
        background-position: calc(100% - 18px) 50%;
    }

    &.p-l input {
        padding-left: 46px;
    }

    &::placeholder {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.01em;
        color: rgba(23, 35, 53, 0.48);
    }

    &.small input {
        height: 40px;
        font-size: 16px;
        line-height: 24px;

        &::placeholder {
            color: rgba($blackBlue, 0.64);
        }
    }

    &.large input {
        height: 60px;
        font-size: 18px;
        border-radius: 10px;

        &::placeholder {
            line-height: 60px;
            font-size: 18px;
        }

        @include mobile {
            height: 48px;
            border-radius: 8px;

            &::placeholder {
                line-height: 48px;
            }
        }
    }

    &.large65 input {
        height: 65px;
        width: 52px;

        font-weight: 500;
        font-size: 32px;
        line-height: 48px;
        text-align: center;

        &::placeholder {
            line-height: 64px;
        }
    }

    &.large80 input {
        height: 80px;
        width: 64px;

        font-weight: 500;
        font-size: 40px;
        line-height: 48px;
        text-align: center;

        &::placeholder {
            line-height: 80px;
        }

        @include mobile {
            height: 60px;
            width: 43px;

            font-weight: bold;
            font-size: 28px;
            line-height: 34px;

            &::placeholder {
                line-height: 60px;
            }
        }
    }

    &.clear-btn input {
        padding-right: 48px;
    }

    &.uppercase input {
        text-transform: uppercase;
    }

    &.hide-appearance {
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number] {
            -moz-appearance: textfield;
        }
    }

    &.placeholder-accent {
        input:not(:focus) {
            &::placeholder {
                color: $blackBlue;
            }
        }
    }

    input {
        @include roundInput;
        cursor: text;
    }

    .icon-after {
        position: absolute;
        top: 50%;
        left: 12px;
        width: 24px;
        height: 24px;
        transform: translateY(-50%);
    }

    .btn {
        position: absolute;
        right: 18px;
        top: 50%;
        width: 24px;
        height: 24px;
        transform: translateY(-50%);
        cursor: pointer;
    }

    .static-input-postfix {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);

        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.01em;
        color: rgba($blackBlue, 0.48);
        user-select: none;
    }
}
</style>

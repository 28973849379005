<template>
    <div class="auth-title">
        <div class="auth-title-main">{{ title }}</div>
        <div class="auth-title-static">Для того, чтобы войти или создать аккаунт</div>
    </div>
</template>

<script>
export default {
    name: "AuthTitle",
    props: {
        title: String
    }
}
</script>

<style lang="scss">
.auth-title {
    color: $blackBlue;
    text-align: center;

    margin-bottom: 24px;
    .auth-title-main {
        font-weight: bold;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -0.02em;

        margin-bottom: 8px;
        @include mobile {
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 6px;
        }
    }
    .auth-title-static {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.01em;
        @include mobile {
            font-size: 12px;
            line-height: 16px;
        }
    }
}
</style>
